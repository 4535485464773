const MOCK_ITEMS = [
    {
        id: '12ad-hgu7-vdft',
        title: 'Lighthouse',
        description: 'Not a real lighthouse',
        img: './img/lighthouse.jpeg',
        date: '21-01-2024',
        likes: 1,
        comments: 0,
        author: {
            name: 'Anna',
            img: ''
        }
    }
]

export const listAllItems = async () => {
    return MOCK_ITEMS
    // return await fetch('/api/items')
    //     .then(response => response.json())
    //     .then(data => {
    //         return data;
    //     });
}