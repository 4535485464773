import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter,
  Route,
  Routes,
  Link,
} from "react-router-dom";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "about",
    element: <div>About</div>,
  },
];


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className=''>
        <div className="h-[48px] bg-white flex items-center px-2 text-xl font-semibold text-slate-500 border-1 border-gray-200">
          <Link to="/" className='text-rose-800 px-2 md:px-6 h-full flex items-center justify-center hover:underline hover:bg-slate-100'>
            Anna Retreats
          </Link>
          {/* <Link to="/" className='px-2 md:px-6 h-full flex items-center justify-center hover:bg-slate-100'>
            About
          </Link> */}
          <div className='grow'></div>
          <div className='text-slate-700 text-[16px] mr-4 px-2 md:px-6 h-full text-[16px] flex items-center justify-center cursor-pointer hover:underline hover:bg-slate-100'>
            Upload new image
          </div>
          <div className='px-2 md:px-6 h-full text-[16px] flex items-center justify-center cursor-pointer hover:underline hover:bg-slate-100'>
            Login
          </div>
        </div>
        <Routes>
            {routes.map((route) => (
                <Route key={route.path} {...route} />
            ))}
        </Routes>
      </div>
    </BrowserRouter>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
