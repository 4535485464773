import './App.css';
import { useEffect, useState } from 'react';
import { listAllItems } from './utils';
import { FaRegHeart } from "react-icons/fa";
import { FaRegComment } from "react-icons/fa";
import { BsCalendarDate } from "react-icons/bs";
import { CiUser } from "react-icons/ci";

const ItemView = (item) => {
  if(!item) return null
  return <div className='bg-white text-slate-700 mt-2 rounded border border-slate-100 px-4 pt-1 rounded'>
    <div className=' flex items-center text-slate-400 text-[12px]'>
      <div className='text-2xl font-semibold grow text-slate-600'> {item.title}</div>
        <div className='italic min-w-[120px] flex items-center'>
          <BsCalendarDate size={14} className='mr-1'/>
          {item.date}</div>
        <div className='min-w-[40px] flex items-center'>
          <FaRegHeart size={14} className='mr-1'/> {item.likes}</div>
        <div className='min-w-[40px] flex items-center'>
          <FaRegComment size={14} className='mr-1'/> {item.comments}</div>
      </div>
    <div className='flex items-center text-slate-400 text-[14px]'>
    <div className='min-w-[120px] flex items-center font-semibold text-slate-500'>
        <CiUser size={18} className='mr-1'/>
        {item.author.name}</div>
      
    </div>
    { item.description && <div className='text-xs py-1 text-slate-400'>{item.description}</div> }
    
    { item.img && <div className='grid place-items-center'> 
        <img alt={item.title} src={item.img} className='w-[100%] object-contain max-h-[600px] my-2' /> 
      </div>}
  </div>
}



function Home() {

  const [items, setItems] = useState([])

  useEffect(() => {
    listAllItems()
      .then(items => setItems(items))
  }, [])

  return <div className='w-full flex justify-center bg-slate-100'>
    <div className='w-[98%] max-w-[960px]'>
      {!items && <div>Loading...</div>}
      {items && items.map(item => <ItemView key={item.id} {...item} />)}
    </div>
    
    </div>
}

export default Home;
